import { Action } from "../reducer/telematicsTabs.reducer";

import { Tab } from "../containers/TelematicsTabs/TelematicsTabs";
import { saveToCache } from "../containers/TelematicsTabs/useTelematicsLogger";

const setVisitedTab = (tab: Tab): Action => ({
  payload: tab,
  type: "TELEMATICS_TABS/SET_VISITED_TAB",
});

const setDateRange = (dateFrom: string, dateTo: string): Action => {
  saveToCache("dateFilter", { dateFrom, dateTo });

  return {
    type: "TELEMATICS_TABS/SET_DATE_RANGE",
    dateFrom,
    dateTo,
  };
};

export { setDateRange, setVisitedTab };
